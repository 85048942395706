import { Action } from '~/actions'

const initialState = {
  plan: {
    id: '',
    amount: 0,
  },
  isMonthly: true,
  userCount: 1,
  box: true,
  paid: false,
  isEmpty: true,
  install: false,
  replyNumber: 0,
}

export type PackState = {
  isMonthly: boolean
  box: boolean
  install: boolean
  isEmpty: boolean
  userCount: number
  paid: boolean
  replyNumber: number
  plan: {
    id: string
    amount: number
  }
}

export default (state: PackState = initialState, action: Action): PackState => {
  switch (action.type) {
    case 'CLEAR':
      return { ...initialState }
    case 'CHOOSE_PACK':
      return { ...state, ...action.pack }
    default:
      return state
  }
}
