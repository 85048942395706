import { Action } from '~/actions'

import { Printer } from '~/services/api'

export type PrintersState = {
  printers: Printer[]
}

const initialState: PrintersState = {
  printers: [],
}

export default (state = initialState, action: Action): PrintersState => {
  switch (action.type) {
    case 'CLEAR':
      return { ...initialState }
    case 'STORE_PRINTERS':
      return { ...state, printers: action.printers }

    default:
      return state
  }
}
