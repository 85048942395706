import React, { ReactNode } from 'react'
import { ApolloProvider } from '@apollo/react-hooks'
import GraphqlClient from '~/services/api'

const client = new GraphqlClient(process.env.GATSBY_API_URL!)

/**
 * This component exists to provide a reusable application wrapper for use in Gatsby API's, testing, etc.
 */
const App = ({ element }: { element: ReactNode }) => {
  return <ApolloProvider client={client.getClient()}>{element}</ApolloProvider>
}

export default App
