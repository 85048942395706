export const idDebug = {
  password: 'P@ssword',
  passwordConfirmation: 'P@ssword',
  job: 'Master of univers',
  companyName: 'Monstres, Inc.',
  siretNumber: '12345678901234',
  activitySector: 'Agroalimentaire',
  companySize: 1,
  locationName: 'Hell',
  address1: '20 Donkeresteeg',
  address2: '',
  country: 'Netherlands',
  town: 'Hell',
  zipCode: '38820',
  stateOrProvince: 'SM',
  longitude: 52.2349516,
  latitude: 5.5350994,
  billingLocationName: 'Billing Hell',
  billingAddress1: '20 Donkeresteeg',
  billingAddress2: '',
  billingCountry: 'Netherlands',
  billingTown: 'Hell',
  billingZipCode: '38820',
  billingStateOrProvince: 'SM',
  billingLongitude: 52.2349516,
  billingLatitude: 5.5350994,
  billingAtSameAddress: true,
  totalAllowedUsers: 1,
}

export const idInitState: any = {
  address2: '',
  billingAddress2: '',
  billingTown: '',
  town: '',
  activitySector: '',
  address1: '',
  billingAddress1: '',
  billingAtSameAddress: false,
  billingCountry: '',
  billingLatitude: undefined,
  billingLocationName: '',
  billingLongitude: undefined,
  billingStateOrProvince: '',
  billingZipCode: '',
  companyName: '',
  companySize: 0,
  country: '',
  latitude: undefined,
  locationName: '',
  longitude: undefined,
  passwordConfirmation: '',
  siretNumber: '',
  stateOrProvince: '',
  zipCode: '',
  password: '',
  job: '',
  totalAllowedUsers: 1,
}

export const partners = [
  {
    distance: 10,
    name: 'Jack Morisson',
    adress1: 'Ovw cedex 42',
    adress2: '15 washington street',
    zipCode: '59000',
    town: 'Lille',
    description: [
      'Installation et configuration sur site dans le Nord',
      'Technicien expérimenté',
      'Maintenance préventive ou curative de tous les équipements et systèmes d’impressions.',
      'Préparation et réparation du matériel en atelier',
      'Gestion du stock des pièces détachées.',
    ],
    prestations: [
      'Configuration',
      'Gestion du serveur d’impression',
      'Audits d’impression',
      'Revente consommables',
    ],
    brands: ['Oxton', 'Lexmark', 'Samsung'],
    certifications: [],
    region: ['59 - Nord'],
  },
  {
    distance: 999,
    name: 'Lena Oxton',
    adress1: 'Ovw cedex 42',
    adress2: '15 washington street',
    zipCode: '59000',
    town: 'Lille',
    description: [
      'Installation et configuration sur site dans le Nord',
      'Technicien expérimenté',
      'Maintenance préventive ou curative de tous les équipements et systèmes d’impressions.',
      'Préparation et réparation du matériel en atelier',
      'Gestion du stock des pièces détachées.',
    ],
    prestations: [
      'Configuration',
      'Gestion du serveur d’impression',
      'Audits d’impression',
      'Revente consommables',
    ],
    brands: ['Oxton', 'Samsung'],
    certifications: [],
    region: ['59 - Nord', '60 - Pas de calais'],
  },
]
