import { Action } from '~/actions'
import { AuthResult } from '~/services/api'

export type AuthState = {
  auth?: AuthResult
  googleToken?: string
  googleDomain?: string
}

const initialState = {}

export default (state = initialState, action: Action): AuthState => {
  switch (action.type) {
    case 'CLEAR':
      return { ...initialState }
    case 'STORE_AUTH':
      return { ...state, auth: action.auth }
    case 'STORE_GOOGLE_INFO':
      return {
        ...state,
        googleToken: action.googleToken,
        googleDomain: action.googleDomain,
      }
    default:
      return state
  }
}
