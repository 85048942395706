export { default as App } from './App'
import { ApolloClient, createHttpLink,  InMemoryCache } from '@apollo/client/core'


export const wpClient = new ApolloClient({
    cache: new InMemoryCache(),
    link: createHttpLink({
      uri: process.env.GATSBY_WORDPRESS_GRAPHQL_URL,
    }),
  })
  