import ApolloClient from 'apollo-boost'
import fetch from 'isomorphic-fetch'

import {
  Products_products,
  Products_products_plans,
  Products_products_skus,
  Products_products_prices,
} from './__generated__/Products'
import {
  Register_register,
  Register_register_user,
} from './__generated__/Register'
import { CompleteRegistrationInput } from '../../../__generated__/globalTypes'

export * from './auth-helper'
export * from './report-helper'
export * from './mutation'

// eslint-disable-next-line @typescript-eslint/camelcase
export type Product = Products_products

export type Sku = Products_products_skus

export type Price = Products_products_prices

export type Plan = Products_products_plans

export type AuthResult = Register_register

export type UpdateRegistration = CompleteRegistrationInput

export type User = Register_register_user

export interface Account {
  password: string
  job: string
  company: string
  siret: string
  activity: string
  employees: string
  address: string
  address2: string
  postalCode: string
  billingAddress: string
  billingAddress2: string
  billingPostalCode: string
}

export interface Partner {
  distance?: number
  name: string
  adress1: string
  adress2: string
  zipCode: string
  town: string
  description: string[]
  prestations: string[]
  brands: string[]
  certifications: string[]
  region: string[]
}

export interface PartnerInvitation {
  company: string
  firstName: string
  lastName: string
  email: string
  phone: string
}

export interface SubscribePack {
  id: number
  name: string
  yearly: boolean
  monthly: boolean
  cost: number
  functionnalities: string[]
}

export interface PasswordInput {
  old: string
  new: string
  confirm: string
}

export default class GraphqlClient {
  private readonly client: ApolloClient<any>

  private token: string | undefined

  constructor(uri: string) {
    this.client = new ApolloClient({
      uri,
      fetch,
      request: (operation) => {
        operation.setContext({
          headers: {
            authorization: this.token ? `Bearer ${this.token}` : undefined,
          },
        })
      },
    })
  }

  getClient = () => this.client

  getToken = () => this.token

  // eslint-disable-next-line no-return-assign
  setToken = (token: string) => (this.token = token)
}
