// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
import humps from 'lodash-humps'

export interface Printer {
  model: string
  vendor: string
  ip: string
  compatibility: string
  capabilities: Capabilities
}

export interface Capabilities {
  canColor: boolean
  canDuplex: boolean
  canScan: boolean
  canFax: boolean
}

export const parsePrinters = (input: string): Printer[] =>
  humps(JSON.parse(input).InlineData)
