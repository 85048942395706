import React from 'react';

import { App } from './src/components/App';

import { CreateProvider } from '~/reducers'

export const shouldUpdateScroll = () => { return [0, 0] }

// Duplicated in gatsby-ssr.js for server side rendering during the build
export const wrapRootElement = props => 
    <CreateProvider>
        <App {...props} />
    </CreateProvider>;
