// @ts-ignore
import humps from 'lodash-humps'
import jwtDecode from 'jwt-decode'

export interface JwtClaims {
  nameid: string
  email: string
  securityStamp: string
  watchdocCustomerId: string
  watchdocTokenType: string
  watchdocAccountType: string
  nfb: number
  exp: number
  iat: number
  claimTokenType: string
}

export const decodeJwt = (jwt: string): JwtClaims => humps(jwtDecode(jwt))
