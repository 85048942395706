import { combineReducers, createStore } from 'redux'

import pack, { PackState } from '~/reducers/pack'
import account, { AccountState } from '~/reducers/account'
import auth, { AuthState } from '~/reducers/auth'
import subscription, { SubscriptionState } from '~/reducers/subscription'
import printers, { PrintersState } from '~/reducers/printers'
import products, { ProductsState } from '~/reducers/products'
import React from 'react'
import { Provider } from 'react-redux'
import GraphqlClient from '~/services/api'
import getConfig from '~/config'
import * as Sentry from '@sentry/browser'
import { isBrowser } from '~/utils/system'

const STATE_STORE_KEY = 'registration_state'

export type State = {
  auth: AuthState
  pack: PackState
  subscription: SubscriptionState
  printers: PrintersState
  account: AccountState
  products: ProductsState
}

export const saveState = (state: State) => localStorage.setItem(STATE_STORE_KEY, JSON.stringify(state))

export const loadState = (): State | undefined => {
  const serializedState = isBrowser() ? localStorage.getItem(STATE_STORE_KEY) : null
  return serializedState === null ? undefined : JSON.parse(serializedState)
}

const reducers = combineReducers({
  pack,
  account,
  auth,
  subscription,
  printers,
  products,
})

const initState = loadState()

const store = createStore(reducers, initState)

const config = getConfig()

Sentry.init({ dsn: config.sentryDSN })

export const client = new GraphqlClient(config.apiUrl)

export const CreateProvider: React.FunctionComponent<any> = ({ children }) => {
  const updateToken = (state: State) => {
    if (state.auth.auth && state.auth.auth.accessToken.token !== client.getToken()) {
      client.setToken(store.getState().auth.auth!.accessToken.token)
    }
  }

  if (initState) updateToken(initState)

  store.subscribe(() => {
    updateToken(store.getState())
    saveState(store.getState())
  })

  return <Provider store={store}>{children}</Provider>
}
export default reducers
