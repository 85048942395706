import { gql } from 'apollo-boost'

export const GET_INSTANCE = gql`
  query GetCustomer($customerId: String!) {
    customer(customerId: $customerId) {
      id
    }
  }
`

export const CREATE_CONTACT_REQUEST = gql`
  mutation SendContactRequest($input: ContactInput!) {
    sendContactRequest(input: $input)
  }
`

export const REGISTER = gql`
  mutation Register($userInput: UserInput!) {
    register(userInput: $userInput) {
      temporaryCode
      accessToken {
        token
        expiration
      }
      user {
        id
        fullName
        email
      }
    }
  }
`

export const CREATE_INSTANCE_USERS = gql`
  mutation CreateCustomerUsers($usersInput: [NewUserInput!]!) {
    createCustomerUsers(usersInput: $usersInput) {
      email
    }
  }
`
export const UPDATE_REGISTRATION = gql`
  mutation UpdateRegistration(
    $completeRegistrationInput: CompleteRegistrationInput!
  ) {
    updateRegistration(completeRegistrationInput: $completeRegistrationInput)
  }
`

export const PROCESS_PAYMENT_INTENT = gql`
  mutation ProcessPaymentIntent($paymentIntentId: String!) {
    processPaymentIntent(paymentIntentId: $paymentIntentId) {
      id
    }
  }
`

export const PAYMENT_INTENT = gql`
  mutation PaymentIntent($paymentIntent: PaymentIntentInput!) {
    paymentIntent(paymentIntent: $paymentIntent) {
      id
      clientSecret
      amount
      status
    }
  }
`

export const SIMULATE_REPORT = gql`
  mutation SImulateReport($report: ScanReportInput!) {
    createScanReport(report: $report)
  }
`

export const CREATE_CONTACT = gql`
  mutation SendContactInfo($input: ContactInfoInput!) {
    sendContactInfo(input: $input)
  }
`

export const COMPLETE_REGISTRATION = gql`
  mutation CompleteRegistration($customerName: String!) {
    completeRegistration(customerName: $customerName)
  }
`
