import { Action } from '~/actions'

export type SubscriptionState = {
  step: number
  hasPartner?: boolean
  hasInvitedUsers: boolean
  askedPartner: boolean
}

const initialState = {
  step: 0,
  hasInvitedUsers: false,
  askedPartner: false,
}

export default (state = initialState, action: Action): SubscriptionState => {
  switch (action.type) {
    case 'CLEAR':
      return { ...initialState }
    case 'INCREMENT_STEP':
      return { ...state, step: state.step + 1 }
    case 'UPDATE_STEP':
      return { ...state, step: action.step }
    case 'UPDATE_INFO':
      return { ...state, ...action.data }
    default:
      return state
  }
}
