import { CompleteRegistrationInput } from '__generated__/globalTypes'
import { Action } from '~/actions'
import { isDev } from '~/helpers/tools'
import { idDebug, idInitState } from '~/screens/steps/data'

export type AccountState = CompleteRegistrationInput

const init = () => (isDev() ? idDebug : idInitState)

export default (state: AccountState = init(), action: Action): AccountState => {
  switch (action.type) {
    case 'CLEAR':
      return { ...init() }
    case 'FILL_ACCOUNT':
      return {
        ...state,
        ...action.account,
      }
    default:
      return state
  }
}
