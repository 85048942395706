// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-a-propos-a-propos-de-nous-tsx": () => import("./../../../src/pages/a-propos/a-propos-de-nous.tsx" /* webpackChunkName: "component---src-pages-a-propos-a-propos-de-nous-tsx" */),
  "component---src-pages-a-propos-contact-tsx": () => import("./../../../src/pages/a-propos/contact.tsx" /* webpackChunkName: "component---src-pages-a-propos-contact-tsx" */),
  "component---src-pages-a-propos-rse-reduire-compenser-tsx": () => import("./../../../src/pages/a-propos/rse-reduire-compenser.tsx" /* webpackChunkName: "component---src-pages-a-propos-rse-reduire-compenser-tsx" */),
  "component---src-pages-a-propos-securite-documents-tsx": () => import("./../../../src/pages/a-propos/securite-documents.tsx" /* webpackChunkName: "component---src-pages-a-propos-securite-documents-tsx" */),
  "component---src-pages-comment-ca-marche-tsx": () => import("./../../../src/pages/comment-ca-marche.tsx" /* webpackChunkName: "component---src-pages-comment-ca-marche-tsx" */),
  "component---src-pages-contact-i-tsx": () => import("./../../../src/pages/contact_i.tsx" /* webpackChunkName: "component---src-pages-contact-i-tsx" */),
  "component---src-pages-faq-business-tsx": () => import("./../../../src/pages/faq/business.tsx" /* webpackChunkName: "component---src-pages-faq-business-tsx" */),
  "component---src-pages-faq-partner-tsx": () => import("./../../../src/pages/faq/partner.tsx" /* webpackChunkName: "component---src-pages-faq-partner-tsx" */),
  "component---src-pages-faq-technical-tsx": () => import("./../../../src/pages/faq/technical.tsx" /* webpackChunkName: "component---src-pages-faq-technical-tsx" */),
  "component---src-pages-fonctionnalites-archivage-probatoire-intelligent-tsx": () => import("./../../../src/pages/fonctionnalites/archivage-probatoire-intelligent.tsx" /* webpackChunkName: "component---src-pages-fonctionnalites-archivage-probatoire-intelligent-tsx" */),
  "component---src-pages-fonctionnalites-gestion-impression-tsx": () => import("./../../../src/pages/fonctionnalites/gestion-impression.tsx" /* webpackChunkName: "component---src-pages-fonctionnalites-gestion-impression-tsx" */),
  "component---src-pages-fonctionnalites-numerisation-universelle-tsx": () => import("./../../../src/pages/fonctionnalites/numerisation-universelle.tsx" /* webpackChunkName: "component---src-pages-fonctionnalites-numerisation-universelle-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-mentions-legales-tsx": () => import("./../../../src/pages/mentions-legales.tsx" /* webpackChunkName: "component---src-pages-mentions-legales-tsx" */),
  "component---src-pages-news-preview-tsx": () => import("./../../../src/pages/news/preview.tsx" /* webpackChunkName: "component---src-pages-news-preview-tsx" */),
  "component---src-pages-offer-capture-tsx": () => import("./../../../src/pages/offer/capture.tsx" /* webpackChunkName: "component---src-pages-offer-capture-tsx" */),
  "component---src-pages-offre-et-tarifs-tsx": () => import("./../../../src/pages/offre-et-tarifs.tsx" /* webpackChunkName: "component---src-pages-offre-et-tarifs-tsx" */),
  "component---src-pages-politique-confidentialite-tsx": () => import("./../../../src/pages/politique-confidentialite.tsx" /* webpackChunkName: "component---src-pages-politique-confidentialite-tsx" */),
  "component---src-pages-registration-tsx": () => import("./../../../src/pages/registration.tsx" /* webpackChunkName: "component---src-pages-registration-tsx" */),
  "component---src-pages-ressources-conseils-gestion-documents-tsx": () => import("./../../../src/pages/ressources/conseils-gestion-documents.tsx" /* webpackChunkName: "component---src-pages-ressources-conseils-gestion-documents-tsx" */),
  "component---src-pages-ressources-faq-tsx": () => import("./../../../src/pages/ressources/faq.tsx" /* webpackChunkName: "component---src-pages-ressources-faq-tsx" */),
  "component---src-templates-single-news-tsx": () => import("./../../../src/templates/single-news.tsx" /* webpackChunkName: "component---src-templates-single-news-tsx" */)
}

