// @ts-nocheck
export interface Config {
  apiUrl: string
  sentryDSN: string
}

export default (): Config => {
  return {
    sentryDSN:
      typeof GATSBY_SENTRY_DSN !== 'undefined'
        ? GATSBY_SENTRY_DSN
        : process.env.GATSBY_SENTRY_DSN!,
    apiUrl:
      typeof GATSBY_API_URL !== 'undefined'
        ? GATSBY_API_URL
        : process.env.GATSBY_API_URL!,
  }
}
