import { Action } from '~/actions'

import { Product } from '~/services/api'

export type ProductsState = {
  products: Product[]
}

const initialState: ProductsState = {
  products: [],
}

export default (state = initialState, action: Action): ProductsState => {
  switch (action.type) {
    case 'STORE_PRODUCTS':
      return { ...state, products: action.products }

    default:
      return state
  }
}
